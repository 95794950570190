body {
    background-color: #8080800f !important;
    color: #002b6f !important;
}

header {
    height: 50px;
    background-color: #fff;
}
.header_container {
    display: flex;
    justify-content: space-between;
}
.logo{
    height: 45px;
}
.logo-oney {
    padding-top: 15px;
    height: 45px;
}

.blocs_balisage {
    margin-top: 75px;
}

.first-bloc {
    display: flex;
    justify-content: space-between;
}

.balisage_title {
    font-weight: 700;
    color: #002b6f;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    padding: 15px;
    margin-top: 0;
    border-bottom: 1px solid #ccc;
}

.format_balisage {
    font-size: 14px;
    color: #002b6f;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
}

.bloc-balisage, .bloc-plv {
    background-color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    margin-bottom: 25px;
    padding: 0;
}

.bloc-balisage {
    min-height: 324px;
    width: 65%;
}

.img_format {
    display: block;
}

.img_format {
    margin-bottom: 15px;
    margin: 0 auto;
}

.img_format.ombre {
    -webkit-box-shadow: 7px 10px 15px rgba(0, 0, 0, 0.2), 0px 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 7px 10px 15px rgba(0, 0, 0, 0.2), 0px 2px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 7px 10px 15px rgba(0, 0, 0, 0.2), 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.img_format.small {
    margin: 0 auto;
}

.payant {
    margin: 0 auto;
    margin-right: 25px;
}

.gratuit {
    margin-bottom: 24px;
    margin-right: 14px;
    margin-left: 14px;
    -webkit-box-shadow: 7px 10px 15px rgba(0, 0, 0, 0.2), 0px 2px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 7px 10px 15px rgba(0, 0, 0, 0.2), 0px 2px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 7px 10px 15px rgba(0, 0, 0, 0.2), 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.free_button, .button_payant {
    display: flex;
    margin-bottom: 10px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 150px;
    background-color: #fcb731;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    padding: 5px 0;
    border: none;
    text-transform: uppercase;
    color: #002b6f;
    font-size: 16px;
}

.link_balisage:hover {
    text-decoration: none !important;
}

.free_button span, .button_payant span {
    margin: 0 auto;
}

.btn_impression {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 150px;
    background-color: #fcb731;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    padding: 5px 0;
}

.btn_impression a {
    color: #002b6f;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}

/* Class génériques */
.p-0 {
    padding: 0 !important;
}

.pb-10 {
    padding-bottom: 10px;
}

/* Page balisage-form */
.btn-retour {
    text-align: right;
    margin-top: 63px;
    position: relative;
}

.titre_table_simu {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.btn-retour input {
    width: 14%;;
    height: 32px;
    background-color: #fcb731;
    color: #002b6f;
    text-transform: uppercase;
    font-size: 16px;
    border: none;
    font-weight: 500;
    border-radius: 2px;
    -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
}

.material-icons.back {
    position: absolute;
    left: 87%;
    top: 9%;
}

#balisage_collection_submit {
    float: right;
    background-color: #fcb731;
    height: 32px;
    width: 255px;
    color: #002b6f;
    border: none;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 2px;
    -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    position: relative;
    padding-left: 25px;
}

.bloc-montant-mini p {
    text-align: center;
    background-color: #002b6f;
    color: #fcb731;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    padding: 3px;
    border-radius: 2px;
    margin-bottom: 25px;
}

/* Tableau choix balisage */
#balisages-fields-list {
    background-color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
    margin-bottom: 25px;
    padding: 0;
    display: block;
}

.colonne_ligne, .colonne_libelle, .colonne_description, .colonne_pv, .colonne_choix, .colonne_cible {
    font-size: 16px;
    color: #002b6f;
    text-transform: uppercase;
    font-weight: 700;
}

#balisages-fields-list td input, #balisages-fields-list td select {
    border: 1px solid #ccc;
}

#balisages-fields-list tr {
    height: 40px;
    border-bottom: 1px solid #ccc;
}

#balisages-fields-list tr .num_balisage {
    text-align: right;
    font-size: 16px;
    color: #002b6f;
    font-weight: 700;
}

#balisages-fields-list tr .text_libelle {
    padding-left: 25px;
}

#balisages-fields-list tr .text_description {
    padding-right: 20px;
}

#balisages-fields-list tr .text_description input {
    width: 100%;
    color: #969696;
}

#balisages-fields-list tr .text_libelle input, #balisages-fields-list tr .text_description input, #balisages-fields-list tr .pv_balisage input, #balisages-fields-list tr .choix_balisage select {
    color: #616161;
    cursor: pointer;
}

.colonne_ligne {
    padding-left: 20px;
    text-align: right;
}

.colonne_libelle {
    padding-left: 25px;
}

.colonne_description {
    width: 30%;
}

.colonne_pv {
    padding-right: 38px;
}

.cible_balisage, .choix_balisage, .text_description, .colonne_cible, .colonne_choix, .colonne_description {
    padding-left: 15px;
}

.cancel_btn {
    width: 1%;
    padding-right: 40px;
}

/* Boutons radio */
/* Masquer le bouton radio d'origine */
input[type=radio] {
    display: none;
}

/* Affichage par défaut du bouton radio personnalisé */
input[type=radio] + label:before {
    background: #fff;
    border: 2px solid #002b6f;
    border-radius: 50%;
    margin-top: -3px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 14px;
    height: 14px;
}

/* Affichage du bouton radio personnalisé quand il est sélectionné */
input[type=radio]:checked + label:before {
    background: #002b6f;
    border-color: #002b6f;
    box-shadow: inset 0px 0px 0px 2px #fff;
}

.element {
    margin-bottom: 14px;
}

label {
    cursor: pointer;
}

input[type=radio] + label[for=balisage_collection_format_1]:after {
    content: url(../img/A4-icone.png);
    margin: 0 40px 0 10px;
}

input[type=radio] + label[for=balisage_collection_format_2]:after {
    content: url(../img/A5-icone.png);
    margin: 0 40px 0 10px;
}

input[type=radio] + label[for=balisage_collection_format_3]:after {
    content: url(../img/mobilite.png);
    margin: 0 40px 0 10px;
}

input[type=radio] + label[for=balisage_collection_format_4]:after {
    content: url(../img/A5-icone-unique.png);
    margin: 0 40px 0 10px;
}

/* Icone print */
i.material-icons.print {
    vertical-align: middle;
    font-size: 20px;
    margin-right: 3px;
}

.print-icone::before {
    content: '\E555';
    font-family: 'Material Icons';
    margin-right: 10px;
    position: absolute;
    left: 17px;
    top: 2px;
    font-size: 20px;
}

.add-balisage-widget {
    font-size: 16px;
    text-transform: uppercase;
    background-color: #002b6f;
    color: #fff;
    padding: 7px;
    border-radius: 2px;
    position: relative;
}

.add-balisage-widget .add_balisage {
    top: 5px;
    position: relative;
    right: 3px;
}

.add-balisage-widget:focus, .add-balisage-widget:hover {
    color: #fff;
    text-decoration: none;
}

/* Responsive */
@media screen and (max-width: 855px) {
    .format_balisage {
        font-size: 20px;
    }

    .img_format {
        width: 65%;
    }
}

@media screen and (max-width: 991px) {
    .container {
        width: 95% !important;
    }
}

.link-icon-opc {
    display: inline-block;
}

.icon-opc {
    background-color: #FCB731;
    min-width: 96px;
    min-height: 96px;
    color: #002B6F;
    font-size: 38px;
    font-weight: 900;
    margin: 1rem 0.5rem 2rem 0.5rem;
/*    -webkit-box-shadow: 11px 7px 32px 0px rgba(209, 209, 209, 1);
    -moz-box-shadow: 11px 7px 32px 0px rgba(209, 209, 209, 1);
    box-shadow: 11px 7px 32px 0px rgba(209, 209, 209, 1);*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.opc-icon-baseline {
    font-size: 11px;
    font-weight: lighter;
    position: relative;
    top: -10px;
}

.opc-icon-rond {
    font-size: 15px;
    font-weight: lighter;
    position: relative;
}

.icon-opc-container {
    display: flex;
    justify-content: center;
}

.icon-opc-promo-img {
    width: 75px;
    position: absolute;
    top: 10px;
    left: 0;
}

.icon-opc-promo-img2{
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    height: 25px;
    z-index: 0;
}

.errors-bloc,
.form-errors {
    display: flex;
    justify-content: center;
    align-items: center;
    color: firebrick;
    font-style: italic;
    font-weight: 700;
}

.errors-list {
    list-style-type: none;
}

.plv-body img {
    max-width: 90%;
    max-height: 325px;
}

.container-opcs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    min-width: 50%;
}

.bloc-opc {
    display: inline-block;
    position: relative;
}
